/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

$navbar-height: 50px;
$font-size-standard: 15px;
* {
    font-family: 'Metropolis' !important;   // font par défaut du projet
}

ion-list{
  background: transparent !important;
}

.sc-ion-picker-md-h{
    --background: var(--ion-color-light-grey) !important;
}

.sc-ion-alert-md-h{
    --background: var(--ion-color-light-grey) !important;
    --backdrop-opacity: 0.30;
    --color: var(--ion-color-dark-grey) !important;
}
/*
.alert-radio-label{
    --color: var(--ion-color-dark-grey);
}*/

.login-container {
    height: $navbar-height;
    ion-button {
        --height: $navbar-height;
        height: $navbar-height;
    }
}
.button-std {
    text-transform: none;
    border-radius: 35px;
    height: 50px;
    font-size: $font-size-standard;
}
.button-std-theme {
    color: var(--ion-color-light-grey);
    background-color: var(--ion-color-light-blue);
}
.button-inactive {
    color: var(--ion-color-inactive-button);
    background-color: var(--ion-color-light-grey-button);
}
.button-active-1 {
    color: var(--ion-color-light-grey);
    background-color: var(--ion-color-light-green);
}
.button-active-2 {
    color: var(--ion-color-light-grey);
    background-color: var(--ion-color-purple);
}
.button-active-3 {
    color: var(--ion-color-light-grey);
    background-color: var(--ion-color-blue-green);
}
.button-save {
    color: var(--ion-color-light-grey);
    background-color: var(--ion-color-medium-green);
}
.buttonRight {
    align-self: flex-end;
}
.button-std:hover {
    opacity: 0.7;
}
.button-last-visit {
    height: 40px;
    color: var(--ion-color-dark-blue);
    background-color: var(--ion-color-light-grey);
    border: 2px solid var(--ion-color-dark-blue)
}
.header {
    height: 90px;
    width: 100%;
    color: var(--ion-color-light-grey);
    background-color: var(--ion-color-dark-blue);
}
.toast {
    --background: var(--Neutre-Fond);
    color: var(--Texte-Contenu);
    --width: 100%;
    --height: 60px;
    --min-width: 100%;
    text-align: center;
    --end: 0;
    --start: 0;
    margin-left: 0px;
    margin-right: 0px;
    --border-radius: 0px;
}
.toastVert {
    --background: var(--Status-Success-Icone);
    color: #ffffff;
    --width: 100%;
    --height: 60px;
    --min-width: 100%;
    text-align: center;
    --end: 0;
    --start: 0;
    margin-left: 0px;
    margin-right: 0px;
    --border-radius: 0px;
}
.toastAlert {
    --background: var(--Status-Warning-Icone);
    color: #ffffff;
    --width: 100%;
    --height: 60px;
    --min-width: 100%;
    text-align: center;
    --end: 0;
    --start: 0;
    margin-left: 0px;
    margin-right: 0px;
    --border-radius: 0px;
}
.toastAlert::part(button){
  display: flex;
  padding: 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--8, 8px);
  border: 1px solid var(--Status-Warning-Texte);
  color: var(--Status-Warning-Texte);
  background-color: #ffffff;
}
.header-text-top {
    color: var(--ion-color-grey-text);
}
.header-text-bottom {
    color: var(--ion-color-light-grey);
}
.modal {
    --height: 250px;
    --width: 400px;
}

.imageModal {
    position: absolute;
	--width: 90%;
    --height: 90%;
    --margin: auto;
    background: rgba(0, 0, 0, 0.75);
//    --background: rgba(0, 0, 0, 0.75);
}
.alertModel .alert-wrapper {
    background-color: var(--ion-color-light-grey);
    --background: var(--ion-color-light-grey);
    color: var(--ion-color-dark-grey);
    --color: var(--ion-color-dark-grey);
}

.repereVisuel {
    width: 25px;
    height: 25px;
    padding-left: 10px;
}

.repereVisuelCache {
    width: 25px;
    height: 25px;
    padding-left: 10px;
    display: none;
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
   }

   .alert-radio-label.sc-ion-alert-md, .alert-radio-label.sc-ion-alert-ios {
     white-space: normal !important;
   }

  ion-toggle::part(track),
  ion-toggle.toggle-checked::part(track) {
    height: 22px;
    width: 38px;
   }

  ion-toggle{
    --handle-height: 18px;
    --handle-width: 18px;
    --handle-border-radius: 9px;
   }
